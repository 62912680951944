import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Api } from "../../../utils/Variables";
import { Col, Row } from "react-bootstrap";
import EMPTY_CART from "../../../assets/img/empty_cart.png";
import PsContext from "../../../context/PsContext";

const Viewcart = (props) => {
  const context = useContext(PsContext);

  useEffect(() => {
    context.scrollTop();
  }, [context]);

  const cartData = context.state.cartData || [];

  if (context.state.cartCount < 1) {
    return (
      <div className="container">
        <Row>
          <Col md={12}>
            <div className="text-center">
              <img src={EMPTY_CART} alt="Empty Cart" />
              <h5>Your cart is empty</h5>
              <Link to="/" className="btn btn-dark mt-4">
                Shop Now
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <>
      <div className="tf-page-title">
        <div className="container-full">
          <div className="heading text-center">Shopping Cart</div>
          <p className="text-center text-2 text_black-2 mt_5">
            Shop through our latest selection of Fashion
          </p>
        </div>
      </div>

      <section className="flat-spacing-11">
        <div className="container">
          <div className="tf-page-cart-wrap">
            <div className="tf-page-cart-item">
              <form>
                <table className="tf-table-page-cart">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartData && cartData.length > 0 ? (
                      cartData.map((product) => {
                        if (!product) return null;

                        return (
                          <tr
                            className="tf-cart-item file-delete"
                            key={product.id}
                          >
                            <td className="tf-cart-item_product">
                              <Link
                                to={`/${product.slug}/p/${product.id}`}
                                className="img-box"
                                onClick={props.onCancel}
                              >
                                <img
                                  src={Api.CloudImageUrl + "/" + product.image}
                                  alt={product.itemname}
                                />
                              </Link>
                              <div
                                className="cart-info"
                                style={{ width: "187px;" }}
                              >
                                <Link
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: "13px",
                                  }}
                                  to={`/${product.slug}/p/${product.id}`}
                                  onClick={props.onCancel}
                                >
                                  {product.itemname}
                                </Link>
                                {/* <span className="remove-cart link remove">
                                  Remove
                                </span> */}
                              </div>
                            </td>
                            <td
                              className="tf-cart-item_price tf-variant-item-price"
                              cart-data-title="Price"
                            >
                              <div className="cart-price price">
                                ₹{product.salesrate}
                              </div>
                            </td>
                            <td
                              className="tf-cart-item_quantity"
                              cart-data-title="Quantity"
                            >
                              <div className="cart-quantity">
                                <div className="wg-quantity">
                                  <span className="btn-quantity btndecrease">
                                    <svg
                                      className="d-inline-block"
                                      width="9"
                                      height="1"
                                      viewBox="0 0 9 1"
                                      fill="currentColor"
                                    >
                                      <path d="M9 1H5.14286H3.85714H0V1.50201e-05H3.85714L5.14286 0L9 1.50201e-05V1Z"></path>
                                    </svg>
                                  </span>
                                  <input
                                    type="text"
                                    name="number"
                                    value={product.quantity}
                                    readOnly
                                  />
                                  <span className="btn-quantity btnincrease">
                                    <svg
                                      className="d-inline-block"
                                      width="9"
                                      height="9"
                                      viewBox="0 0 9 9"
                                      fill="currentColor"
                                    >
                                      <path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z"></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td
                              className="tf-cart-item_total tf-variant-item-total"
                              cart-data-title="Total"
                            >
                              <div className="cart-total price">
                                <em className="text-muted">
                                  {product.quantity} x ₹{product.salesrate}
                                </em>
                              </div>
                            </td>
                            <td>
                              <Link
                                to="/cart"
                                style={{ fontSize: "13px" }}
                                onClick={props.onCancel}
                              >
                                Checkout
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">No items in the cart</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Viewcart;
