import React, { useState, useContext, useEffect } from "react";

import PsContext from "../../../context/PsContext";

import CartModal from "./cartModal";

const styles = {
  cartInRight: {
    position: "absolute",
    top: "13px",
    right: "-13px",
  },
};

const CartLink = () => {
  const context = useContext(PsContext);
  const [showModal, setModal] = useState(false);

  /**
   * to handle cart button click
   * if the count of cart items in localstorage is greater than zero then showModal is set true
   */
  const cartClick = () => {
    if (context.state.cartCount > 0) {
      setModal(!showModal);
      context.showCart(!context.state.showCartModal);
    }
  };

  return (
    <>
      {/* desktop view cart icon */}
      <li className="nav-cart">
        <a onClick={cartClick} className="nav-icon-item my-menu-1">
          <i className="icon icon-bag"></i>
          <span className="count-box">{context.state.cartCount}</span>
        </a>
      </li>

      {context.state.showCartModal && <CartModal onCancel={cartClick} />}
    </>
  );
};

export default CartLink;
