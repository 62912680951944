import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-hot-toast";

import API from "../../../utils/API";
import PsContext from "../../../context/PsContext";
import ProductLoader from "../Home/productLoader";
import { FeaturedProduct } from "../../elements";
import NO_PRODUCT_IMG from "../../../assets/img/empty.webp";

const NewSearch = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(true);
  const [products, setProducts] = useState([]);
  const keyWord = props.match.params.keyword;

  useEffect(() => {
    loadData();
  }, [keyWord]);

  const loadData = () => {
    setLoader(true);
    setProducts([]);
    API.get("v1/site/item/search/" + keyWord).then((res) => {
      if (res["data"].status == "1") {
        setProducts(res["data"].data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const addToCart = (id) => {
    var d = products;
    var index = d.findIndex(function (obj) {
      return obj.id == id;
    });
    var dat = d[index];
    dat["quantity"] = 1;
    context.addToCart(dat);
    toast.success("Successfully added to cart!.");
  };

  return (
    <div className="container-fluid py-3">
      <Row>
        <Col md={12}>
          <h5 style={{ color: "black", fontSize: "18px" }}>
            Search Text : {keyWord}{" "}
          </h5>
        </Col>
      </Row>
      {loader && <ProductLoader size={8} />}

      <Row>
        {products &&
          products.length > 0 &&
          products.map((item, i) => {
            return (
              <Col md={3}>
                <FeaturedProduct
                  title={item.itemname}
                  image={item.image}
                  id={item.id}
                  height="320px"
                  item={item}
                  amount={item.salesrate}
                  addToCart={() => {
                    addToCart(item.id);
                  }}
                />
              </Col>
            );
          })}
      </Row>

      {!loader && products && products.length < 1 && (
        <center>
          <br />
          <br />
          <img
            style={{ width: "100%", maxWidth: "300px" }}
            src={NO_PRODUCT_IMG}
          />
          <h4 className="mt-5" style={{ fontSize: "20px" }}></h4>
        </center>
      )}
    </div>
  );
};
export default withRouter(NewSearch);
