import React, { Memo, useContext, useEffect } from "react";
import { Redirect, Link, NavLink } from "react-router-dom";
import $ from "jquery";
import PsContext from "../../../context/PsContext";
import LOGO from "../../../assets/img/footer-shibani.png";

const Footer = () => {
  const context = useContext(PsContext);

  const d = context.state.company;

  const topCategories = [
    { text: "Festival", to: "/category/festival" },
    { text: "Holy-Communion", to: "/category/holy-communion" },
    { text: "Wedding Collections", to: "/category/wedding" },
    { text: "Receptions", to: "/category/receptions" },
    { text: "Bridal", to: "/category/bridal" },
  ];

  return (
    <footer id="footer-1" className="footer-1 footer mt-50">
      <div className="footer-wrap">
        <div className="footer-body p-0 m-0">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-12">
                <div className="footer-infor">
                  <div className="footer-logo mt-50">
                    <Link to="/">
                      <a href="" className="logo-header">
                        <img
                          src={LOGO}
                          alt="Logo"
                          style={{ width: "270px" }}
                          className="logo"
                        />
                      </a>
                    </Link>
                  </div>
                  <ul className="pt-50">
                    <li>
                      <p
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className="mb-5"
                      >
                        Address:
                        <a href="#">
                          1234 Fashion Street, Suite 567, Tamilnadu
                        </a>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className="mb-5"
                      >
                        Email: <a href="#">shibanifashion2015@gmail.com</a>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className="mb-5"
                      >
                        Phone: <a href="#">6374327362</a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="single-footer f-link">
                  <h3>Information</h3>
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/refund-policy">Refund Policy</Link>
                    </li>
                    <li>
                      <Link to="/shipping-policy">Shipping Policy</Link>
                    </li>
                    <li>
                      <Link to="/disclaimer">Disclaimer</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="single-footer f-link">
                  <h3>Top Categories</h3>
                  <ul>
                    {topCategories.map((item) => (
                      <li>
                        <Link to={item.to}>{item.text}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-12">
                <div className="single-footer f-link">
                  <h3>Location</h3>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d126374.82835087663!2d77.3416901!3d8.1812467!3m2!1i1024!2i768!4f13.1!2m1!1sshibani%20fashion!5e0!3m2!1sen!2sin!4v1743745722069!5m2!1sen!2sin"
                    style={{ border: "0", width: "100%", height: "220px" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container-fluid">
          <div className="inner-content">
            <div className="row align-items-center">
              <div className="col-lg-6 col-12 pb-0">
                <div className="copyrights d-flex justify-content-center">
                  <p
                    style={{
                      fontSize: "13px",
                      color: "#000",
                    }}
                    className="mb-2 text-start"
                  >
                    &copy; 2025 All Right Reserved{" "}
                    <a href="#" title="Peace Soft Technology" className="ms-20">
                      Shibani fashions
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12 pb-0">
                <div className="copyrights d-flex justify-content-center">
                  <p
                    style={{
                      fontSize: "13px",
                      color: "#000",
                    }}
                    className="mb-2 text-center"
                  >
                    Designed and Developed by{" "}
                    <a
                      href="https://peacesoft.in"
                      title="Peace Soft Technology"
                      target="_blank"
                      className="ms-20"
                    >
                      Peacesoft Technologies
                    </a>
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-4 col-12 text-right footer_ps_links">
                <ul className="socila">
                  <li>
                    <a
                      href="https://www.facebook.com/SHIBANIFASHION/"
                      target="_blank"
                    >
                      <i className="lni lni-facebook-filled bg-black"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-twitter-original"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/shibanifashion/"
                      target="_blank"
                    >
                      <i className="lni lni-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
