import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Api } from "../../../utils/Variables";
import { makeUrl } from "../../../utils";

class Featured extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var d = this.props;
    const { item } = this.props;

    return (
      <>
        <div className="card-product-wrapper">
          <Link to={`/${makeUrl(item.slug)}/p/${d.id}`} className="product-img">
            <img
              className="lazyload img-product"
              data-src={Api.CloudImageUrl + d.image}
              src={Api.CloudImageUrl + d.image}
              alt={d.title}
            />
            <img
              className="lazyload img-hover"
              data-src={Api.CloudImageUrl + d.image}
              src={Api.CloudImageUrl + d.image}
              alt={d.title}
            />
          </Link>
          <div className="list-product-btn absolute-2">
            {item && item.stockinhand && parseFloat(item.stockinhand) > 0 && (
              <a
                onClick={this.props.addToCart}
                className="box-icon bg_white quick-add tf-btn-loading"
              >
                <span className="icon icon-bag"></span>
                <span className="tooltip">Add to Cart</span>
              </a>
            )}
            {/* {item && item.stockinhand && parseFloat(item.stockinhand) > 1 && (
              <Link
                to={`/${item.slug}/p/${d.id}`}
                className="box-icon bg_white quick-add tf-btn-loading"
              >
                <span className="icon icon-bag"></span>
                <span className="tooltip">Add to Cart</span>
              </Link>
            )} */}
          </div>
        </div>
        <div className="card-product-info">
          <Link to={`/${item.slug}/p/${d.id}`} className="title link">
            {d.title}
          </Link>
          <span className="price current-price">₹ {d.amount}</span>
        </div>
      </>
    );
    return (
      <div className="card card-product mb-3">
        <div className="card-body " style={{ padding: "0px" }}>
          <Link to={`/${item.slug}/p/${d.id}`}>
            <img
              className="card-img-top lazy_products"
              src={Api.ImageUrl + "cimage/thumb/" + d.id}
              alt={d.title}
              style={{ width: "100%" }}
            />
          </Link>
          <div
            className="card-title"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link
              to={`/${item.slug}/p/${d.id}`}
              className=" text-dark"
              style={{ fontSize: "15px" }}
            >
              <b>{d.title}</b>
            </Link>
          </div>
          <div className="price">
            <span className="h5">Rs. {d.amount}</span>
          </div>
        </div>
        <div className="card-footer">
          {item && item.stockinhand && parseFloat(item.stockinhand) > 0 && (
            <button
              className="btn btn-sm rounded-pill btn-theme btn-block atc-demo"
              onClick={this.props.addToCart}
            >
              Add to Cart
            </button>
          )}

          {item && item.stockinhand && parseFloat(item.stockinhand) < 1 && (
            <Link
              className="btn btn-sm rounded-pill btn-theme btn-block atc-demo"
              to={`/${item.slug}/p/${d.id}`}
            >
              Add to Cart
            </Link>
          )}
        </div>
      </div>
    );
  }
}
export default Featured;
