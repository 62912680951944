import React, { useContext, useState } from "react";
import { Redirect, Link, NavLink } from "react-router-dom";

import { Modal } from "react-bootstrap";
import PsContext from "../../../context/PsContext";
import LoginForm from "../../pages/userAccount/loginForm";
import TopSearchBar from "../header/topSearchBar";
const TopHeader = () => {
  const context = useContext(PsContext);
  const [show, showModal] = useState(false);

  return (
    <div className="main-header line">
      <div className="container-full px_15 lg-px_40">
        <div className="row py-1 wrapper-header align-items-center">
          <div className="col-xl-2 tf-md-hidden">
            <ul className="header-list-categories">
              <li className="categories-item">
                <Link to="/about">About Us</Link>
              </li>
              <li className="categories-item">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-xl-2 md-6 tf-lg-hidden">
            <TopSearchBar />
          </div>
          <div className="col-md-5 col-3 tf-md-hidden">
            <ul className="header-list-categories">
              <li className="categories-item">
                <a href={`mailto:${context.state.company.email}`}>
                  <i className="icon fs-12 icon-mail"></i>{" "}
                  {context.state.company.email}
                </a>
              </li>
              <li className="categories-item">
                <a href="#" className="text-uppercase">
                  Phone: {context.state.company.mobile}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-3 tf-md-hidden">
            <div className="tf-top-bar_wrap grid-3 gap-30">
              <ul className="tf-top-bar_item tf-social-icon d-flex gap-10  align-items-end">
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-facebook bg_line"
                  >
                    <i className="icon fs-12 icon-fb"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-twiter bg_line"
                  >
                    <i className="icon fs-10 icon-Icon-x"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-instagram bg_line"
                  >
                    <i className="icon fs-12 icon-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-tiktok bg_line"
                  >
                    <i className="icon fs-12 icon-tiktok"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-pinterest bg_line"
                  >
                    <i className="icon fs-12 icon-pinterest-1"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-3 md-6 tf-md-hidden">
            <ul className="header-list-categories  d-flex justify-content-end align-items-center">
              <li className="categories-item ">
                {context.state.userLogged == "yes" ? (
                  <a
                    href="javascript:;"
                    className="nav-link nav-link-sm has-icon"
                    onClick={() => context.userLogout()}
                  >
                    <i class="icon icon-account"></i> Log Out
                  </a>
                ) : (
                  <a
                    href="javascript:;"
                    className="nav-link nav-link-sm has-icon"
                    onClick={() => showModal(true)}
                  >
                    <i class="icon icon-account  fs-icon-text me-2"></i>{" "}
                    <span className=" fs-icon-text">Log In/Sign In</span>
                  </a>
                )}
              </li>
            </ul>
          </div>
          <Modal show={show} onHide={() => showModal(false)} backdrop="static">
            <Modal.Header closeButton className="text-white">
              Sign In / Sign Up
            </Modal.Header>
            <Modal.Body>
              <LoginForm afterFinish={() => showModal(false)} />

              <br />
              <br />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
